<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-cloud-download font-scale="3" animation="cylon" />
        <p id="cancel-label">กรุณารอสักครู่...</p>
      </div>
    </template>
    <div class="d-flex justify-content-between flex-wrap" style="gap: 10px">
      <!-- Date -->
      <div class="d-flex justify-content-end align-items-center">
        <b-form-group class="" label="วันที่เริ่มต้น">
          <b-form-datepicker
            v-model="dateStart"
            placeholder="เลือกวันที่เริ่มต้น"
            class="date-input mr-2 mb-1"
             :locale="locale"
                v-bind="labels[locale] || {}"
          />
        </b-form-group>
        <b-form-group class="" label="วันที่สิ้นสุด">
          <b-form-datepicker
            v-model="dateEnd"
            placeholder="เลือกวันที่สิ้นสุด"
            class="date-input mr-2 mb-1"
             :locale="locale"
                v-bind="labels[locale] || {}"
          />
        </b-form-group>
        <!-- Date -->
        <b-form-group
          class="mr-2 mt-1 d-flex justify-content-end align-items-center"
        >
          <b-button style="width: 100px" variant="info" @click="getTpo()">
            ค้นหา
            <i class="ms-2 fal fa-search" />
          </b-button>
        </b-form-group>
        <!-- Export -->
        <b-form-group class="d-flex justify-content-end align-items-center">
          <b-button
            style="box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1)"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            class="mt-1"
            variant="gradient-success"
            block
            @click="exportData()"
          >
            Export
          </b-button>
        </b-form-group>
        <!-- Export -->
      </div>
    </div>
    <b-card no-body>
      <!-- <b-card-body>
            </b-card-body> -->
      <b-table
        striped
        hover
        responsive
        show-empty
        class="position-relative items-center"
        :per-page="perPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + (data.index + 1) }}
        </template>
        <template #empty="scope">
          <div class="p-2 text-center">
            <img src="/box.png" alt="empty" height="40px" />
            {{ scope.emptyFiltered ? 'No matching records
                        found' : 'ไม่พบข้อมูล' }}
          </div>
        </template>

        <template #cell(bank_info)="data">
          <div v-if="data.item.bank_info.length > 0">
            <b-button
              pill
              variant="danger"
              @click="showbank(data.item.bank_info)"
            >
              {{ data.item.bank_info.length }} ธนาคาร
            </b-button>
          </div>
          <p v-else>-</p>
        </template>
        <template #cell(phone_info)="data">
          <div v-if="data.item.phone_info.length > 0">
            <b-button
              pill
              variant="primary"
              @click="showtel(data.item.phone_info)"
            >
              {{ data.item.phone_info.length }} เบอร์โทร
            </b-button>
          </div>
          <p v-else>-</p>
        </template>

        <template #cell(channel_info)="data">
          <div v-if="data.item.channel_info.length > 0">
            <b-button
              pill
              variant="warning"
              @click="showtelaccout(data.item.channel_info)"
            >
              {{ data.item.channel_info.length }} ช่องทางติดต่อผู้ร้าย
            </b-button>
          </div>
          <p v-else>-</p>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="8"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
            @change="getTpo()"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            @input="getTpo()"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-card>

    <b-modal
      class=""
      ref="bankmd"
      hide-footer
      title="ธนาคาร"
      header-bg-variant="gradient-danger"
      title-class="custom-title"
    >
      <div class="d-block text-center">
        <b-table
          striped
          hover
          :items="items_bank"
          :fields="fields_bank"
        ></b-table>
      </div>
      <b-button
        class="mt-2"
        variant="outline-danger"
        block
        @click="$refs['bankmd'].hide()"
        >ปิด</b-button
      >
    </b-modal>

    <b-modal
      class=""
      ref="phonemd"
      hide-footer
      title="เบอร์โทร"
      header-bg-variant="gradient-primary"
      title-class="custom-title"
    >
      <div class="d-block text-center">
        <b-table
          striped
          hover
          :items="items_tel"
          :fields="fields_tel"
        ></b-table>
      </div>
      <b-button
        class="mt-2"
        variant="outline-primary"
        block
        @click="$refs['phonemd'].hide()"
        >ปิด</b-button
      >
    </b-modal>

    <b-modal
      class=""
      ref="channelmd"
      hide-footer
      title="ช่องทางติดต่อผู้ร้าย"
      header-bg-variant="gradient-warning"
      title-class="custom-title"
    >
      <div class="d-block text-center">
        <b-table
          striped
          hover
          :items="items_channel"
          :fields="fields_channel"
        ></b-table>
      </div>
      <b-button
        class="mt-2"
        variant="outline-warning"
        block
        @click="$refs['channelmd'].hide()"
        >ปิด</b-button
      >
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BRow,
  BCol,
  BInputGroupPrepend,
  BModal,
  BCard,
  BTable,
  BAvatar,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BButton,
  BCardBody,
  VBToggle,
  BOverlay,
  BIconCloudDownload,
  VBTooltip,
  BFormDatepicker,
  // BFormCheckbox,
  BFormTextarea,
  BForm,
  BFormFile,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// import { parse } from "json2csv";
import moment from 'moment-timezone'
// import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BRow,
    BCol,
    BInputGroupPrepend,
    BModal,
    BCard,
    BTable,
    BAvatar,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    // BInputGroupAppend,
    BFormTextarea,
    BButton,
    BCardBody,
    BOverlay,
    BForm,
    BIconCloudDownload,
    BFormFile,
    BButton,
    BFormDatepicker,
    // BFormCheckbox,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      level: 1,
      level_option: [],
      fullName: '',
      mobile: '',
      remark: '',
      search_type_option: [
        { value: 1, text: 'สถานะ' },
        { value: 2, text: 'ธนาคาร' },
        { value: 3, text: 'เบอร์โทร' },
        { value: 4, text: 'เลขบัญชี' },
      ],
      search_type: null,
      search_val: null,
      show: true,
      prolist: [],
      pro_id: null,
      depositdata: [],
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      usersList: [],
      datasidebar: [],
      banklist: [],
      update_id: null,
      edit_id: null,
      edit_password: null,
      edit_name: null,
      edit_surname: null,
      edit_bank: null,
      edit_acc_no: null,
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'index', label: 'ลำดับ' },
        { key: 'case_id', label: 'Case ID' },
        { key: 'bank_info', label: 'ธนาคาร' },
        { key: 'phone_info', label: 'เบอร์โทร' },
        { key: 'channel_info', label: 'ช่องทางติดต่อผู้ร้าย' },
        {
          key: 'stamp_date',
          label: 'วัน/เดือน/ปี',
          formatter: (value) =>
            value ? moment(value).tz('Asia/Bangkok').format('DD/MM/YYYY') : '',
        },
      ],
      fields_date: [
        { key: 'date_start', label: 'วันที่เริ่มต้น' },
        { key: 'date_end', label: 'วันที่สิ้นสุด' },
      ],
      fields_bank: [
        { key: 'BANK_NAME', label: 'ชื่อธนาคาร' },
        { key: 'BANK_ACCOUNT', label: 'เลขธนาคาร' },
        { key: 'BANK_ACCOUNT_NAME', label: 'ชื่อผู้ใช้' },
      ],
      fields_tel: [{ key: 'PHONE', label: 'เบอร์โทร' }],
      fields_channel: [
        { key: 'CHANNEL_TYPE', label: 'ประเภทช่องทาง' },
        { key: 'CHANNEL_DETAIL', label: 'รายละเอียด' },
      ],
      items_tel: [],
      items_channel: [],
      items_bank: [],
      items: [],
      addNewDataSidebar: false,
      UserData: JSON.parse(localStorage.getItem('userData')),
      dateStart: moment().tz('Asia/Bangkok').format('YYYY-MM-DD'),
      dateEnd: moment().tz('Asia/Bangkok').format('YYYY-MM-DD'),
      selectedFile: null,
      locale: "th",
      labels: {
        th: {
          labelPrevDecade: "ทศวรรษก่อนหน้า",
          labelPrevYear: "ปีก่อนหน้า",
          labelPrevMonth: "เดือนก่อนหน้า",
          labelCurrentMonth: "เดือนปัจจุบัน",
          labelNextMonth: "เดือนถัดไป",
          labelNextYear: "ปีถัดไป",
          labelNextDecade: "ทศวรรษถัดไป",
          labelToday: "วันนี้",
          labelSelected: "วันที่ที่เลือก",
          labelNoDateSelected: "ยังไม่ได้เลือกวันที่",
          labelCalendar: "ปฏิทิน",
          labelNav: "การนำทางปฏิทิน",
          labelHelp: "ใช้ปุ่มลูกศรเพื่อเลือกวันที่",
        },
      },
      locales: [{ value: "th", text: "Thai (th)" }],
    }
  },
  computed: {
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    //   this.totalRows = this.items.length
    //   this.getLevel()
    this.getTpo()
  },

  methods: {
    showbank(item) {
      this.items_bank = item
      this.$refs['bankmd'].show()
      console.log(item)
    },
    showtel(item) {
      this.items_tel = item
      this.$refs['phonemd'].show()
      console.log(item)
    },
    showtelaccout(item) {
      this.items_channel = item
      this.$refs['channelmd'].show()
      console.log(item)
    },
    // showModal() {
    //     this.$refs['testmd'].show()
    // },
    // hideModal() {
    //     this.$refs['testmd'].hide()
    // },
    handleFileChange(event) {
      this.selectedFile = event.target.files[0]
    },
    importData() {
      if (this.selectedFile) {
        this.show = true
        const formData = new FormData()
        formData.append('csv', this.selectedFile)

        // เรียกใช้ฟังก์ชันส่งข้อมูลโดยใช้ Axios
        this.$http
          .post('/sms/ImportCsv', formData)
          .then((response) => {
            this.getTpo()
            this.Success(response.data.message)
            this.selectedFile = null
            this.show = false
          })
          .catch((error) => {
            this.show = false
            this.SwalError(error.response.data.message)
          })
      } else {
        console.error('No file selected')
      }
    },
    async getLevel() {
      try {
        const { data: res } = await this.$http.get('/call/showlevel')
        this.level_option = res
      } catch (error) {
        console.log(error)
      }
    },
    rowClass(item) {
      const FindLevel = this.level_option.find((x) => x.value === item.level)
      if (FindLevel) {
        return {
          color: FindLevel.color,
          text: FindLevel.text,
        }
      }
      return {
        color: 'secondary',
        text: '-',
      }
    },

    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    async getTpo() {
      this.show = true
      const params = {
        page: this.currentPage,
        perpage: this.perPage,
        // search_val: this.search_val,
        // search_type: this.search_type,
        sdate: this.dateStart,
        edate: this.dateEnd,
      }
      await this.$http
        .get('/tpo/data', { params })
        .then((response) => {
          this.show = false
          this.items = response.data.tpoData
          this.totalRows = response.data.total

          // this.onFiltered(response.data);
        })
        .catch((error) => console.log(error))
    },
    getBalance(username, index) {
      const params = {
        username,
      }
      this.$http
        .get('/balance/getbalance', { params })
        .then((response) => {
          this.show = false
          this.depositdata[index].updated_at = response.data.balance
        })
        .catch((error) => console.log(error))
    },
    onFiltered(filteredItems) {
      this.items = filteredItems.data
      this.totalRows = filteredItems.total
    },

    async exportData() {
      this.show = true
      const params = {
        sdate: this.dateStart,
        edate: this.dateEnd,
      }
      try {
        const response = await this.$http.get('/Export/TPO', { params })
        this.show = false
        const bom = new Uint8Array([0xef, 0xbb, 0xbf])
        const csvData = new Blob([bom, response.data], {
          type: 'text/csv;charset=utf-8;',
        })

        // สร้างชื่อไฟล์ด้วยวันที่และเวลา
        const now = new Date()
        const dateString = now.toISOString().slice(0, 10) // YYYY-MM-DD
        const timeString = now.toTimeString().split(' ')[0].replace(/:/g, '-') // HH-MM-SS
        const fileName = `TPO_${dateString}_${timeString}.csv`

        const link = document.createElement('a')
        const url = window.URL.createObjectURL(csvData)
        link.href = url
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } catch (error) {
        console.log(error)
        this.show = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-invoice.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.form-item-section {
  background-color: $product-details-bg;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.date-input {
  white-space: nowrap;
  text-overflow: ellipsis;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 8px;
  width: auto;
  text-align: center;
  background-color: rgb(255, 255, 255);
  color: #000000;
  font-size: 14px;
  transition: border-color 0.3s ease;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
}

.date-input:hover {
  border-color: #003b7a;
}

.date-input:focus {
  border-color: #242424;
  outline: none;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
}

.date-input-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.date-label {
  font-weight: bold;
  font-size: 14px;
  color: #343a40;
}

.custom-title {
  color: #ffffff;
}
</style>
